
import { mapGetters } from 'vuex'
import filters from "@/mixins/filters";

export default {
	mixins: [filters],
	computed: {
		...mapGetters({
			settings: 'settings/settings/settings',
			allMarks: 'marks/marks/allMarks',

		})
	},

}
